/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

import {
    ChevronIconComponent as SourceChevronIconComponent,
} from 'SourceComponent/ChevronIcon/ChevronIcon.component';
import { ReactElement } from 'Type/Common.type';

import './ChevronIcon.override.style';

/** @namespace Satisfly/Component/ChevronIcon/Component */
export class ChevronIconComponent extends SourceChevronIconComponent {
    render(): ReactElement {
        const { direction } = this.props;

        return (
            <svg
              block="ChevronIcon"
              mods={ { direction } }
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
                <path d="M9 18L15 12L9 6" stroke="#051B28" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
        );
    }
}

export default ChevronIconComponent;
