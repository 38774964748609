/* eslint-disable spaced-comment,@scandipwa/scandipwa-guidelines/jsx-no-props-destruction */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

import { FieldNumberWithControlsContainer } from 'Component/FieldNumberWithControls/FieldNumberWithControls.container';
import {
    FieldComponent as SourceFieldComponent,
} from 'SourceComponent/Field/Field.component';
import { noopFn } from 'Util/Common';

import './Field.override.style';

/**
 * Field
 * @class Field
 * @namespace Satisfly/Component/Field/Component
 */
export class FieldComponent extends SourceFieldComponent {
    renderNumberWithControls() {
        const {
            attr,
            events,
            setRef,
            value,
            isDisabled = false,
        } = this.props;

        return (
            <FieldNumberWithControlsContainer
              value={ value }
              attr={ attr }
              events={ events }
              setRef={ setRef }
              isDisabled={ isDisabled }
            />
        );
    }

    renderCheckboxOrRadio() {
        const {
            type,
            setRef,
            attr: { defaultChecked = false, ...newAttr } = {},
            events: { onChange },
            events,
            isDisabled,
            label,
        } = this.props;

        const {
            id = '',
            checked,
            value = '',
        } = newAttr;
        const elem = type.charAt(0).toUpperCase() + type.slice(1);
        const inputEvents = {
            ...events,
            onChange: onChange || noopFn,
        };
        // if button value is "none" do not disable
        const isButtonDisabled = (!String(value).match('none') && isDisabled);
        const isChecked = checked || (isButtonDisabled || defaultChecked ? !isDisabled : null);

        return (
            <label htmlFor={ id } block="Field" elem={ `${elem}Label` } mods={ { isDisabled } }>
                <input
                  ref={ (elem) => setRef(elem) }
                  disabled={ isButtonDisabled ? isDisabled : false }
                  type={ type }
                  { ...newAttr }
                  { ...inputEvents }
                  // shipping options have checked attr assigned so prioritize its value
                  defaultChecked={ !!isChecked }
                />
                <div block="input-control" mods={ { disabled: isDisabled } } />
                { label }
            </label>
        );
    }
}

export default FieldComponent;
