/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

import {
    FieldNumberWithControlsContainer as SourceFieldNumberWithControlsContainer,
} from 'SourceComponent/FieldNumberWithControls/FieldNumberWithControls.container';

import FieldNumberWithControls from './FieldNumberWithControls.component';

/**
 * Field Number With Controls
 * @class FieldNumberWithControlsContainer
 * @namespace Satisfly/Component/FieldNumberWithControls/Container */
export class FieldNumberWithControlsContainer extends SourceFieldNumberWithControlsContainer {
    componentDidUpdate(prevProps) {
        const { attr: { min = 0, defaultValue = min, isVariant = false } = {} } = this.props;
        const { attr: { defaultValue: prevDefaultValue = 0 } = {} } = prevProps;

        if ((defaultValue <= 0 || prevDefaultValue <= 0) && !isVariant) {
            // eslint-disable-next-line react/no-did-update-set-state
            this.setState({ value: min });
        }

        if (defaultValue < min || prevDefaultValue < min) {
            this.handleInitialLoad(min);
        }
    }

    handleValueChange(value, e = null, isCartItemButton = false) {
        const {
            events: { onChange } = {},
        } = this.props;

        if (e) {
            e.stopPropagation();
            e.preventDefault();
        }

        const newValue = this.setValue(value);

        if (typeof onChange === 'function') {
            onChange(Number(newValue), isCartItemButton);
        }
    }
}

export default FieldNumberWithControls;
