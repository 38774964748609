/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

export const FIELD_TYPE = {
    email: 'email',
    text: 'text',
    number: 'number',
    radio: 'radio',
    checkbox: 'checkbox',
    textarea: 'textarea',
    password: 'password',
    select: 'select',
    file: 'file',
    multi: 'multi',
    date: 'date',
    time: 'time',
    'datetime-local': 'datetime-local',
    submit: 'submit',
    button: 'button',
    number_with_controls: 'number_with_controls',
    tel: 'tel',
};

export const FieldType = {
    EMAIL: 'email',
    TEXT: 'text',
    NUMBER: 'number',
    RADIO: 'radio',
    CHECKBOX: 'checkbox',
    TEXTAREA: 'textarea',
    PASSWORD: 'password',
    SELECT: 'select',
    FILE: 'file',
    MULTI: 'multi',
    DATE: 'date',
    TIME: 'time',
    DATETIME: 'datetime-local',
    SUBMIT: 'submit',
    BUTTON: 'button',
    NUMBER_WITH_CONTROLS: 'number_with_controls',
    TEL: 'tel',
};

export const FIELD_RADIO_NONE = 'none';

export default FIELD_TYPE;
