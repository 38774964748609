/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

import { CmsPageComponent as SourceCmsPageComponent } from 'SourceRoute/CmsPage/CmsPage.component';
import { setLoadedFlag } from 'Util/Request/LowPriorityLoad';

import './CmsPage.override.style';

/** @namespace Satisfly/Route/CmsPage/Component */
export class CmsPageComponent extends SourceCmsPageComponent {
    componentDidMount() {
        setLoadedFlag();
    }
}

export default CmsPageComponent;
