import {
    FieldSelectComponent as SourceFieldSelectComponent,
} from 'SourceComponent/FieldSelect/FieldSelect.component';

import './FieldSelect.override.style';

/** @namespace Satisfly/Component/FieldSelect/Component */
export class FieldSelectComponent extends SourceFieldSelectComponent {
    // TODO implement logic
}

export default FieldSelectComponent;
