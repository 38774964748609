/* eslint-disable max-len */
/* eslint-disable no-unused-vars */
/* eslint-disable spaced-comment */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa-theme
 * @link https://github.com/scandipwa/scandipwa-theme
 */

import {
    VALIDATION_MESSAGES as SOURCE_VALIDATION_MESSAGES,
    ValidationInputTypeNumber,
    ValidationInputTypeText,
} from 'SourceUtil/Validator/Config';

export * from 'SourceUtil/Validator/Config';

export enum ValidationInputTypeInput {
    EMAIL = 'email',
    PASSWORD = 'password',
    DATE = 'date',
    PHONE = 'phone',
    EMAILLIST = 'emailList',
    POSTCODE = 'postcode',
    VAT = 'vat',
}

export const ValidationInputType = {
    ...ValidationInputTypeInput,
    ...ValidationInputTypeNumber,
    ...ValidationInputTypeText,
    NUMERICSLASH: 'numericSlash',
};

export const VALIDATION_INPUT_TYPE = {
    ...ValidationInputType,
};

export const VALIDATION_MESSAGES = {
    ...SOURCE_VALIDATION_MESSAGES,
    [ValidationInputType.POSTCODE]: __('Incorrect postcode!'),
    [ValidationInputType.VAT]: __('Incorrect vat number!'),
    [ValidationInputType.NUMERICSLASH]: __('Incorrect input! Numeric-Slash value required!'),
};

export const plRegexp = 'a-zA-ZąćęłńóśźżĄĆĘŁŃÓŚŹŻ\\-\\s';

export const VALIDATION_RULES = {
    // Text
    [ValidationInputType.ALPHA]: new RegExp(`^[${ plRegexp }]+$`, 'i'),
    [ValidationInputType.ALPHASPACE]: new RegExp(`^[${ plRegexp }]+$`, 'i'),
    [ValidationInputType.ALPHANUMERIC]: /^[a-z0-9]+$/i,
    [ValidationInputType.ALPHADASH]: /^[a-z0-9_\\-]+$/i,
    [ValidationInputType.URL]: /^((http|https):\/\/(\w+:{0,1}\w*@)?(\S+)|)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\\/]))?$/,

    // Numbers
    [ValidationInputType.NUMERIC]: /^[\d]+$/,
    [ValidationInputType.NUMERICDASH]: /^[\d\-\s]+$/,
    [ValidationInputType.NUMERICSLASH]: /^[\d\\/\s]+$/,
    [ValidationInputType.INTEGER]: /^\\-?[\d]+$/,
    [ValidationInputType.DECIMAL]: /^\d*\.?\d*$/,
    [ValidationInputType.NATURAL]: /^[\d]+$/i,
    [ValidationInputType.NATURALNOZERO]: /^[1-9][\d]*$/i,

    // Inputs
    [ValidationInputType.EMAIL]: /^(([^<>()\\[\]\\.,;:\s@"]+(\.[^<>()\\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z0-9]{2,}))$/,
    [ValidationInputType.DATE]: /\d{4}-\d{1,2}-\d{1,2}/,
    [ValidationInputType.PHONE]: /^(?:(?:(?:(?:\+|00)\d{2})?[ -]?(?:(?:\(0?\d{2}\))|(?:0?\d{2})))?[ -]?(?:\d{3}[- ]?\d{2}[- ]?\d{2}|\d{2}[- ]?\d{2}[- ]?\d{3}|\d{7})|(?:(?:(?:\+|00)\d{2})?[ -]?\d{3}[ -]?\d{3}[ -]?\d{3}))$/,
    [ValidationInputType.EMAILLIST]: /^(([a-zA-Z0-9_\\-\\.]+)@([a-zA-Z0-9_\\-\\.]+)\.([a-zA-Z]{2,5}){1,25})+([,\s]+(([a-zA-Z0-9_\\-\\.]+)@([a-zA-Z0-9_\\-\\.]+)\.([a-zA-Z]{2,5}){1,25})+)*$/,
    [ValidationInputType.POSTCODE]: /^\d{2}-\d{3}$/,
    [ValidationInputType.VAT]: /^[A-Z\d]{10}$/,
};
