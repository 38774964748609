/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-conditional */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import ExpandableContent from 'Component/ExpandableContent';
import Html from 'Component/Html';

import './CmsBlock.override.style.scss';

/**
 * Cms Block
 * @class CmsBlock
 * @namespace Satisfly/Component/CmsBlock/Component */
export class CmsBlockComponent extends PureComponent {
    static defaultProps = {
        cmsBlock: [],
        blockType: '',
        expandedSections: null,
        children: null,
    };

    static propTypes = {
        children: PropTypes.arrayOf(PropTypes.element),
        blockType: PropTypes.string,
        cmsBlock: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.object])),
        expandedSections: PropTypes.arrayOf(PropTypes.string),
    };

    renderPlaceholder() {
        const {
            children,
        } = this.props;

        if (children && (!Array.isArray(children) || children.length)) {
            return children;
        }

        return null;
    }

    renderContent(block) {
        const {
            blockType,
            expandedSections,
        } = this.props;

        const {
            identifier,
            content,
            disabled,
            title,
        } = block;

        if (!content || disabled) {
            return null;
        }

        if (identifier === undefined) {
            return this.renderPlaceholder();
        }

        if (blockType === 'expandable') {
            return (
                <ExpandableContent
                  heading={ title }
                  key={ title }
                  mix={ { block: 'CmsBlock', elem: 'Expandable' } }
                  isArrow
                  isContentExpanded={ !!expandedSections?.find((o) => o === identifier) }
                >
                    <Html content={ content } />
                </ExpandableContent>
            );
        }

        return (
            <Html content={ content } key={ title } />
        );
    }

    render() {
        const { cmsBlock, blockType } = this.props;

        return (
            <div
              block="CmsBlock"
              elem="Wrapper"
              mods={ { type: blockType } }
            >
                { cmsBlock.map((block) => this.renderContent(block)) }
            </div>

        );
    }
}

export default CmsBlockComponent;
