/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
/* eslint-disable max-len */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

import { lazy, Suspense } from 'react';

import RenderWhenVisible from 'Component/RenderWhenVisible';
import {
    WidgetFactoryComponent as SourceWidgetFactoryComponent,
} from 'SourceComponent/WidgetFactory/WidgetFactory.component';
import { noopFn } from 'Util/Common';
import history from 'Util/History';
import { appendWithStoreCode, isHomePageUrl } from 'Util/Url';

import {
    HOMEPAGE_MAIN_SECTION,
    Widget,
    YOUTUBE_START_BANNER,
} from './WidgetFactory.config';

import './WidgetFactory.override.style';

export const ProductListWidget = lazy(() => import(
    /* webpackMode: "lazy", webpackChunkName: "widget-product-list" */
    'Component/ProductListWidget'
));
export const NewProducts = lazy(() => import(
    /* webpackMode: "lazy", webpackChunkName: "widget-new-product" */
    'Component/NewProducts'
));
export const HomeSlider = lazy(() => import(
    /* webpackMode: "lazy", webpackChunkName: "widget-slider" */
    'Component/SliderWidget'
));
export const RecentlyViewedWidget = lazy(() => import(
    /* webpackMode: "lazy", webpackChunkName: "widget-recently-viewed" */
    'Component/RecentlyViewedWidget'
));

export const YoutubeStartBanner = lazy(() => import(
    /* webpackMode: "lazy", webpackChunkName: "widget-yt-start-banner" */
    'Component/YoutubeStartBanner'
));

export const HomepageMainSection = lazy(() => import(
    /* webpackMode: "lazy", webpackChunkName: "widget-home-page-secion" */
    'Component/HomepageMainSection'
));

export const BestSellers = lazy(() => import(
    /* webpackMode: "lazy", webpackChunkName: "widget-bestsellers" */
    'Component/BestSellers'
));

export const NewsSection = lazy(() => import(
    /* webpackMode: "lazy", webpackChunkName: "widget-news-section" */
    'Component/NewsSection'
));

/** @namespace Satisfly/Component/WidgetFactory/Component */
export class WidgetFactoryComponent extends SourceWidgetFactoryComponent {
    reactComponentsRenderMap = {
        YoutubeStartBanner,
        HomepageMainSection,
        BestSellers,
        NewsSection,
    };

    renderMap = {
        [Widget.SLIDER]: {
            component: HomeSlider,
            fallback: this.renderSliderFallback,
        },
        [Widget.NEW_PRODUCTS]: {
            component: NewProducts,
        },
        [Widget.CATALOG_PRODUCT_LIST]: {
            component: ProductListWidget,
        },
        [Widget.RECENTLY_VIEWED]: {
            component: RecentlyViewedWidget,
        },
        [Widget.REACT_COMPONENT]: {
            component: this.reactComponentsRenderMap[this.props.componentName],
            fallback: this.props.componentName === HOMEPAGE_MAIN_SECTION ? this.renderHomepageMainSectionFallback : noopFn,
        },
    };

    renderHomepageMainSectionFallback() {
        return (
            <div block="WidgetFactory" elem="HomepageMainSectionPlaceholder" />
        );
    }

    renderContent() {
        const {
            type,
            sliderId = 0,
            displayType,
            productsCount,
            showPager,
            storeId,
            title,
            conditionsEncoded,
            paramsJson,
            onLoad,
        } = this.props;
        const {
            component: Widget,
            fallback = () => <div />,
        } = this.renderMap[type] || {};

        if (Widget !== undefined) {
            const { location: { pathname = appendWithStoreCode('/') } } = history;

            if (this.props.componentName === YOUTUBE_START_BANNER && !isHomePageUrl(pathname)) {
                return (
                    <Widget
                      sliderId={ sliderId }
                      displayType={ displayType }
                      productsCount={ productsCount }
                      showPager={ !!showPager }
                      storeId={ storeId }
                      title={ title }
                      conditionsEncoded={ conditionsEncoded }
                      paramsJson={ paramsJson }
                      onLoad={ onLoad }
                    />
                );
            }

            return (
                <Suspense fallback={ fallback() }>
                    <RenderWhenVisible>
                        <Widget
                          sliderId={ sliderId }
                          displayType={ displayType }
                          productsCount={ productsCount }
                          showPager={ !!showPager }
                          storeId={ storeId }
                          title={ title }
                          conditionsEncoded={ conditionsEncoded }
                          paramsJson={ paramsJson }
                          onLoad={ onLoad }
                        />
                    </RenderWhenVisible>
                </Suspense>
            );
        }

        return null;
    }
}

export default WidgetFactoryComponent;
