/* eslint-disable no-sequences */
/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-props-destruction */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

import AddIcon from 'Component/AddIcon';
import { FieldType } from 'Component/Field/Field.config';
import MinusIcon from 'Component/MinusIcon';
import {
    FieldNumberWithControlsComponent as SourceFieldNumberWithControlsComponent,
} from 'SourceComponent/FieldNumberWithControls/FieldNumberWithControls.component';
import { DEFAULT_MAX_PRODUCTS } from 'Util/Product/Product.type';

import './FieldNumberWithControls.override.style.scss';
/**
 * Field Number With Controls
 * @class FieldNumberWithControls
 * @namespace Satisfly/Component/FieldNumberWithControls/Component */
export class FieldNumberWithControlsComponent extends SourceFieldNumberWithControlsComponent {
    render() {
        const {
            attr: { isVariant, customRange, ...restAttr },
            attr: { min = 1, max = DEFAULT_MAX_PRODUCTS },
            value,
            events,
            setRef,
            stateValue,
            handleValueChange,
            isDisabled,
        } = this.props;

        const numberValue = +value || +stateValue;

        return (
            <>
                <input
                  ref={ (elem) => setRef(elem) }
                  { ...restAttr }
                  { ...events }
                  value={ value }
                  readOnly={ !!customRange }
                  type={ FieldType.NUMBER }
                  aria-label={ __('Value') }
                  disabled={ isDisabled }
                  onChange={ (e) => handleValueChange(e.target.value, e) }
                  onClick={ (e) => {
                      e.preventDefault(); e.stopPropagation();
                  } }
                />
                <button
                  disabled={ max === 1 || numberValue >= max || isDisabled }
                  onClick={ (e) => handleValueChange(numberValue + (customRange || 1), e, restAttr?.isCartItemButton) }
                  aria-label={ __('Add') }
                  type={ FieldType.BUTTON }
                >
                    <AddIcon block="SubtractButton" isPrimary />
                </button>
                <button
                  disabled={ numberValue + 1 === min || numberValue <= min || isDisabled }
                  onClick={ (e) => handleValueChange(numberValue - (customRange || 1), e, restAttr?.isCartItemButton) }
                  aria-label={ __('Subtract') }
                  type={ FieldType.BUTTON }
                >
                    <MinusIcon block="AddButton" isPrimary />
                </button>
            </>
        );
    }
}

export default FieldNumberWithControlsComponent;
