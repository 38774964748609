export const Widget = {
    SLIDER: 'Slider',
    NEW_PRODUCTS: 'NewProducts',
    CATALOG_PRODUCT_LIST: 'CatalogProductList',
    RECENTLY_VIEWED: 'RecentlyViewed',
    REACT_COMPONENT: 'ReactComponent',
};

export const HOMEPAGE_MAIN_SECTION = 'HomepageMainSection';
export const YOUTUBE_START_BANNER = 'YoutubeStartBanner';
