/* eslint-disable @scandipwa/scandipwa-guidelines/no-arrow-functions-in-class */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

import AddIcon from 'Component/AddIcon';
import ChevronIcon from 'Component/ChevronIcon';
import { Directions } from 'Component/ChevronIcon/ChevronIcon.config';
import MinusIcon from 'Component/MinusIcon';
import XIcon from 'Component/XIcon';
import { ExpandableContentComponent as SourceExpandableContentComponent } from 'SourceComponent/ExpandableContent/ExpandableContent.component';

import './ExpandableContent.override.style';

/** @namespace Satisfly/Component/ExpandableContent/Component */
export class ExpandableContentComponent extends SourceExpandableContentComponent {
    static defaultProps = {
        heading: '',
        isContentExpanded: false,
        onClick: undefined,
        children: [],
        isArrow: false,
        mods: {},
        isMenu: false,
    };

    renderTogglePlusMinus = () => {
        const { isContentExpanded } = this.state;
        const { isMenu } = this.props;

        if (isContentExpanded) {
            return isMenu ? <ChevronIcon direction={ Directions.RIGHT } /> : <MinusIcon />;
        }

        return isMenu ? <XIcon /> : <AddIcon />;
    };
}

export default ExpandableContentComponent;
