import { FieldContainer as SourceFieldContainer } from 'SourceComponent/Field/Field.container';
import { validate } from 'Util/Validator';

import { FieldType } from './Field.config';

/** @namespace Satisfly/Component/Field/Container */
export class FieldContainer extends SourceFieldContainer {
    validate(data) {
        const {
            validationRule: { range: { max: maxValidLength = 0 } = {} }, type, attr: { name } = {},
        } = this.props;
        const { showLengthError } = this.state;
        const newValidRule = this.handleShowLengthError();
        const value = type === FieldType.CHECKBOX || type === FieldType.RADIO
            ? !!(this.fieldRef)?.checked
            : this.fieldRef?.value;

        if (!(type === FieldType.CHECKBOX || type === FieldType.RADIO) && !value && value !== '') {
            return false;
        }

        const response = validate(type === FieldType.FILE
            && typeof value === 'string'
            ? value.toLowerCase()
            : value, newValidRule);
        const output = response !== true ? { ...response, type, name } : response;

        // If validation is called from different object you can pass object
        // to store validation error values
        if (data && data.detail && typeof output !== 'boolean') {
            if (!data.detail.errors) {
                // eslint-disable-next-line no-param-reassign
                data.detail.errors = [];
            }

            // Validates length on submit, renders special message
            if (output.errorMessages
                && typeof value === 'string'
                && maxValidLength
                && value.length > maxValidLength
                && !showLengthError
            ) {
                this.setState({ showLengthError: true });
                output.errorMessages.unshift(__('Please enter no more than %s characters.', maxValidLength));
            }

            data.detail.errors.push(output);
        }

        // When submit and response equals true (it can be object) reset show length error
        if (response === true) {
            this.setState({ showLengthError: false });
        }

        this.setState({ validationResponse: output });

        return output;
    }
}

export default FieldContainer;
