/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */
import CmsBlockQuery from 'Query/CmsBlock.query';
import DataContainer from 'Util/Request/DataContainer';

import CmsBlock from './CmsBlock.component';

/** @namespace Satisfly/Component/CmsBlock/Container */
export class CmsBlockContainer extends DataContainer {
    static defaultProps = {
        blockType: 'default',
        expandedSections: null,
    };

    state = {
        cmsBlock: [],
    };

    __construct(props) {
        super.__construct(props, 'CmsBlockContainer');
    }

    containerProps() {
        const { blockType, children, expandedSections } = this.props;
        const { cmsBlock } = this.state;

        return {
            cmsBlock, blockType, children, expandedSections,
        };
    }

    componentDidMount() {
        this._getCmsBlock();
    }

    componentDidUpdate(prevProps) {
        const { identifier } = this.props;
        const { identifier: prevIdentifier } = prevProps;

        if (identifier !== prevIdentifier) {
            this._getCmsBlock();
        }
    }

    _getCmsBlock() {
        const { identifier } = this.props;

        const identifiers = Array.isArray(identifier) ? identifier : [identifier];

        this.fetchData(
            [CmsBlockQuery.getQuery({ identifiers })],
            (data) => {
                if (!data?.cmsBlocks?.items.length) {
                    return;
                }

                this.setState({ cmsBlock: data?.cmsBlocks?.items });
            },
        );
    }

    render() {
        return (
            <CmsBlock
              { ...this.containerProps() }
            />
        );
    }
}

export default CmsBlockContainer;
