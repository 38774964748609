/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

import {
    CloseIconComponent as SourceCloseIconComponent,
} from 'SourceComponent/CloseIcon/CloseIcon.component';
import { ReactElement } from 'Type/Common.type';

/** @namespace Satisfly/Component/CloseIcon/Component */
export class CloseIconComponent extends SourceCloseIconComponent {
    render(): ReactElement {
        return (
            <svg
              block="CloseIcon"
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
                <path d="M18 6L6 18M6 6L18 18" stroke="#051B28" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
        );
    }
}

export default CloseIconComponent;
