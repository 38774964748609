import CmsBlock from 'Component/CmsBlock/CmsBlock.container';
import ContentWrapper from 'Component/ContentWrapper';
import RenderWhenVisible from 'Component/RenderWhenVisible';
import { FooterComponent as SourceFooterComponent } from 'SourceComponent/Footer/Footer.component';

import { FOOTER_COPYRIGHT } from './Footer.config';

import './Footer.override.style.scss';

/** @namespace Satisfly/Component/Footer/Component */
export class FooterComponent extends SourceFooterComponent {
    renderCmsBlockWrapper() {
        return (
            <div
              block="Footer"
              elem="CmsBlockWrapper"
              mix={ { block: 'Footer', elem: 'Content' } }
            >
                <div
                  block="Footer"
                  elem="Columns"
                  mix={ { block: 'ContentWrapper' } }
                >
                    <div block="Footer" elem="FooterLinks">
                    <CmsBlock
                      identifier={ [
                          'footer-address',
                          'footer-contact',
                          'footer-information',
                          'footer-socials',
                          'footer-sales',
                      ] }
                      blockType="expandable"
                      expandedSections={ ['footer-contact'] }
                    />
                    </div>
                    <div block="Footer" elem="FooterNewsletter">
                        { this.renderNewsletterSubscriptionBlock() }
                    </div>
                </div>
            </div>
        );
    }

    renderContent() {
        return this.renderCmsBlockWrapper();
    }

    renderCopyrightContent() {
        return (
            <ContentWrapper
              mix={ { block: 'Footer', elem: 'CopyrightContentWrapper' } }
              wrapperMix={ { block: 'Footer', elem: 'CopyrightContent' } }
              label={ __('Copyright') }
            >
               <CmsBlock identifier={ [FOOTER_COPYRIGHT] } />
            </ContentWrapper>
        );
    }

    render() {
        const { isVisibleOnMobile, device } = this.props;

        if (!isVisibleOnMobile && device.isMobile) {
            return null;
        }

        if (isVisibleOnMobile && !device.isMobile) {
            return null;
        }

        return (
            <RenderWhenVisible>
                <footer block="Footer" aria-label={ __('Footer') }>
                    { this.renderContent() }
                </footer>
                { this.renderCopyrightContent() }
            </RenderWhenVisible>
        );
    }
}

export default FooterComponent;
