/* eslint-disable @scandipwa/scandipwa-guidelines/only-render-in-component */
import { FieldType } from 'Component/Field/Field.config';
import { FieldContainerProps } from 'Component/Field/Field.type';
import FieldForm from 'Component/FieldForm';
import { FormContainerProps } from 'Component/Form/Form.type';
import { ReactElement } from 'Type/Common.type';

import newsletterSubscriptionForm from './NewsletterForm.form';
import { NewsletterSubscriptionComponentProps } from './NewsletterSubscription.type';

import './NewsletterSubscription.override.style.scss';

/**
 * Newsletter Subscription form
 * @class NewsletterSubscription
 * @namespace Satisfly/Component/NewsletterSubscription/Component */
export class NewsletterSubscriptionComponent extends FieldForm<NewsletterSubscriptionComponentProps> {
    fieldMap(): Partial<FieldContainerProps>[] {
        const { onConsentChange } = this.props;

        return newsletterSubscriptionForm({onConsentChange});
    }

    renderActions(): ReactElement {
        return (
            <button
              type={ FieldType.SUBMIT }
              block="Button"
              aria-label={ __('Submit') }
            >
                { __('Subscribe') }
            </button>
        );
    }

    renderFormBody(): ReactElement {
        const { isLoading } = this.props;

        return (
            <div block="FieldForm" elem="Fieldset" mods={ { isLoading } }>
                <h3 block="NewsletterSubscription" elem="NewsletterTitle">Newsletter</h3>
                <p block="NewsletterSubscription" elem="NewsletterDescription">
                    { __('Subscribe to our newsletter today and receive discounts on your next purchases !!') }
                </p>
                { super.renderFormBody() }
            </div>
        );
    }

    getFormProps(): Partial<FormContainerProps> {
        const { onFormSubmit } = this.props;

        return {
            onSubmit: onFormSubmit,
        };
    }

    render(): ReactElement {
        return (
            <div block="NewsletterSubscription">
                { super.render() }
            </div>
        );
    }
}

export default NewsletterSubscriptionComponent;
