/* eslint-disable max-len */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

import { FieldType } from 'Component/Field/Field.config';
import { FieldContainerProps } from 'Component/Field/Field.type';
import { ValidationInputType } from 'Util/Validator/Config';

import { NewsletterFormEvents } from './NewsletterSubscription.type';

/**
 * Returns fields for newsletter
 * @returns {[{validateOn: [string], validationRule: {isRequired: boolean, inputType: string}, type: string, attr: {name: string, placeholder: *, 'aria-label': *}}]}
 * @namespace Satisfly/Component/NewsletterSubscription/NewsletterForm/Form/newsletterSubscriptionForm */
export const newsletterSubscriptionForm = (events: NewsletterFormEvents): Partial<FieldContainerProps>[] => {
    const { onConsentChange } = events;

    return [
        {
            type: FieldType.EMAIL,
            attr: {
                name: 'newsletterEmail',
                placeholder: __('Enter your email address'),
                'aria-label': __('Email address'),
            },
            validateOn: ['onChange'],
            validationRule: {
                inputType: ValidationInputType.EMAIL,
                isRequired: true,
            },
        },
        {
            type: FieldType.CHECKBOX,
            label: __('I consent to the processing of my personal data by Meringer. You can cancel your subscription at any time for free.'),
            attr: {
                name: 'newsletterConsent',
            },
            events: {
                onChange: onConsentChange,
            },
            validateOn: ['onChange'],
            validationRule: {
                isRequired: true,
            },
        },
    ];
};

export default newsletterSubscriptionForm;
